.attendance-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.attendance-header {
    text-align: center;
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.section-period-select,
.teacher-select {
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.section-period-select select,
.teacher-select select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.group-container {
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #ffffff;
}

.group-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

.student-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #f1f1f1;
}

.student-item label {
    font-size: 1rem;
    color: #333;
}

.attendance-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.attendance-button:hover {
    background-color: #45a049;
}

.section-period-item {
    padding: 12px;
    background-color: #e9ecef;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
}

.section-period-item:hover {
    background-color: #d1d3d4;
}

/* Ensure attendance options (P, A, T) align on the same line */
.attendance-options {
    display: flex;
    align-items: center;
    gap: 10px; /* Adds spacing between options */
}

/* Base styles for attendance options */
.attendance-options label {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

/* Default unselected state */
.attendance-options label {
    background-color: #f8f8f8;
    color: #333;
}

.attendance-options label:hover {
    background-color: #e0f7fa;
}

/* Highlight the selected option */
.attendance-options label.selected {
    background-color: #4caf50; /* Green for selected */
    color: white;
    border-color: #4caf50;
}

/* Compact the excuse dropdown width */
.excuse-select {
    width: 150px; /* Adjust width to be less wide */
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
}

/* Make the comment input wider */
.comment-input {
    width: 400px; /* Adjust to a wider size */
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Responsive layout for student row */
.student-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.student-name {
    flex: 1;
    min-width: 120px;
    font-size: 14px;
    font-weight: bold;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .student-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .excuse-select,
    .comment-input {
        width: 100%;
    }
}

/* CourseHome.css */
.course-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa; /* Light gray background */
  padding: 20px;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #343a40; /* Dark gray color */
}

.course-buttons {
  display: flex;
  gap: 15px; /* Space between buttons */
}

.btn {
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-info {
  background-color: #17a2b8;
  color: white;
}

.btn-info:hover {
  background-color: #117a8b;
}

.btn-warning {
  background-color: #ffc107;
  color: white;
}

.btn-warning:hover {
  background-color: #e0a800;
}

/* src/components/NotFound.css */
.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 3rem;
  color: #ff0000;
}

.not-found p {
  font-size: 1.5rem;
  margin: 20px 0;
}

.not-found a {
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
}

.not-found a:hover {
  text-decoration: underline;
}

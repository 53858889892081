/* App.css */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: #333;
}

.nav-brand {
  font-size: 1.5em;
  color: #61dafb;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
  padding: 0;
  margin: 0;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: #61dafb;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

.container {
  padding: 20px;
  text-align: left;
}

/* Scoped override for AssignHomeroom */
.assign-homeroom .container {
  display: block !important; /* Prevent flex from affecting children */
}

.feature-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.feature-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.feature-button:hover {
  background-color: #0056b3;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/components/NotFound.css */
.not-found {
  text-align: center;
  margin-top: 50px;
}

.not-found h1 {
  font-size: 3rem;
  color: #ff0000;
}

.not-found p {
  font-size: 1.5rem;
  margin: 20px 0;
}

.not-found a {
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
}

.not-found a:hover {
  text-decoration: underline;
}

/* Ensure full height for html and body */
html, body {
  height: 100%;
  margin: 0;
}

/* Make the App component a flex container that spans full height */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header and footer styling */
.App-header {
  flex-shrink: 0; /* Prevent header from resizing */
}

footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
  flex-shrink: 0; /* Prevent footer from resizing */
}

/* Main content fills the available space between header and footer */
main {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Override for AssignHomeroom */
main.assign-homeroom {
  display: block !important; /* Ensure block layout for AssignHomeroom */
  padding: 20px;
}

/* Scoped override for ManageGroups */
.groups-home main {
  display: block; /* Allow grid-based layout */
}

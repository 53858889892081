/* General Layout for Groups Page */
.groups-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 100%;
    max-width: 1400px;
}

/* Top Section: Filters and Actions */
.groups-home .top-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 1400px;
}

.groups-home .filter-group select,
.groups-home .filter-group input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.groups-home .filter-group button {
    padding: 8px 12px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.groups-home .filter-group button:hover {
    background-color: #0056b3;
}
/* Bottom Section: Groups and Students */
.groups-home .layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 1400px;
}

.groups-home .students-box {
    max-height: 250px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 5px;
}
/* Left Column: Unassigned Students */
.groups-home .students-list {
    flex: 1;
    max-width: 250px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 80vh;
    overflow-y: auto; /* Enable scrolling for long lists */
}

/* Unassigned Student Items */
.groups-home .student-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: grab; /* Set grab cursor for draggable items */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 10px;
    flex: 1 1 150px; /* Responsive box size */
    max-width: 200px; /* Optional: Limit max width */
}

.groups-home .student-item:active {
    cursor: grabbing; /* Change to grabbing cursor when dragging */
}

.groups-home .student-item:hover {
    background-color: #f0f8ff;
    border-color: #007bff;
}

/* Right Section: Groups */
.groups-home .group-list {
    flex: 4; /* Occupy remaining space */
    display: grid; /* Grid layout for groups */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive columns */
    gap: 20px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow-y: auto; /* Enable scrolling if necessary */
}

.groups-home .group {
    background-color: #ffffff;
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Group Header */
.groups-home .group h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Group Students */
.groups-home .group-students {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Compact grid layout */
    gap: 10px;
    max-height: 250px; /* Limit height for large groups */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}


/* Individual Student in a Group */
.groups-home .group-student {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    cursor: default;
}

.groups-home .student-name {
    flex-grow: 1;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Handle long names gracefully */
    margin-right: 10px;
}


.groups-home .group-student:active {
    cursor: grabbing; /* Change to grabbing cursor when dragging */
}

.groups-home .group-student:hover {
    background-color: #f0f8ff;
    border-color: #007bff;
}

/* Highlight for Active Drop Zones */
.highlight-drop {
    background-color: #e6ffe6; /* Light green to indicate active drop zone */
    border-color: #28a745;
}

/* Remove Student Button */
.groups-home .remove-student-btn {
    background-color: #ff4d4d;
    border: none;
    color: white;
    padding: 3px 8px;
    font-size: 11px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}


.groups-home .remove-student-btn:hover {
    background-color: #d93636; /* Darker red on hover */
    transform: scale(1.05); /* Slightly enlarge the button */
}

.groups-home .remove-student-btn:active {
    background-color: #c32e2e; /* Even darker red on click */
    transform: scale(0.95); /* Slightly shrink on click for feedback */
}

/* Responsive Design */
@media screen and (max-width: 600px) {
    .groups-home .layout {
        flex-direction: column;
    }

    .groups-home .group-list {
        grid-template-columns: 1fr;
    }
}

/* Buttons */
.groups-home .create-groups-btn {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.groups-home .create-groups-btn:hover {
    background-color: #0056b3;
}

.group-count {
    font-size: 0.9em;
    font-weight: normal;
    color: #555;
    margin-left: 5px;
}


.student-item.in-group {
    background-color: #dff0d8; /* Light green for in-group students */
    border-color: #3c763d; /* Green border */
    color: #3c763d; /* Green text */
}

.student-item.in-group .badge {
    background-color: #3c763d;
    color: white;
    padding: 2px 5px;
    margin-left: 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
}

.student-item.highlighted {
    background-color: #d1e7dd; /* Light green */
    border: 1px solid #198754; /* Green border */
    color: #155724; /* Dark green text */
    font-weight: bold;
}

/* Students Box Layout for ManageGroups.js */
.students-box {
    width: 100%; /* Span across the page */
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex; /* Horizontal layout for items */
    flex-wrap: wrap; /* Allow wrapping to the next row */
    gap: 10px;
    margin-bottom: 20px; /* Add spacing below the box */
    overflow-y: auto; /* Enable scrolling if needed */
    max-height: 200px; /* Optional: Limit height for better usability */
}

.students-box .student-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1 1 150px; /* Responsive box size */
    max-width: 200px; /* Optional: Limit max width of each student */
}

.students-box .student-item:hover {
    background-color: #f0f8ff;
    border-color: #007bff;
}

.remove-zone {
    background-color: #f9f9f9;
    border: 2px dashed #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    color: #666;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.remove-zone.highlight-drop {
    background-color: #ffe6e6;
    border-color: #ff4d4d;
}

.group-container {
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.group-header h3 {
    margin: 0;
    font-size: 16px;
}
.remove-group {
    display: inline-block;
    margin-left: 10px;
}

.remove-group-btn {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
}

.remove-group-btn:hover {
    background-color: #d93636;
}

.confirm-remove {
    margin-top: 5px;
}

.confirm-btn {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.cancel-btn {
    background-color: #ddd;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.cancel-btn:hover {
    background-color: #bbb;
}

/* General Layout for CTE Groups */
.cte-groups-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 100%;
    max-width: 1400px;
}

/* Layout for CTE Group List */
.cte-group-list {
    background-color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust for smaller columns */
    grid-auto-rows: auto; /* Adjust row heights */
    gap: 20px;
    padding: 15px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 5px;
    border: 1px solid rgb(221, 221, 221);
}
/* Individual Group Student List */
.cte-group-students {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Compact grid layout */
    gap: 10px;
    max-height: 250px; /* Limit height for large groups */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Students Box for Unassigned Students (CTE) */
.cte-students-box {
    flex: 1;
    max-height: 250px; /* Adjust for better usability */
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap; /* Wrap items if too many */
    gap: 10px;
}

/* Unassigned Student Item (CTE) */
.cte-student-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: grab;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.cte-student-item:active {
    cursor: grabbing;
}

.cte-student-item:hover {
    background-color: #eaf2ff;
    border-color: #007bff;
}

/* Highlight Dragged Students */
.cte-student-item.dragging {
    opacity: 0.5;
}

/* Groups Container for CTE */
.cte-group {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: border-color 0.3s ease, background-color 0.3s ease;
}

.cte-group.highlight-drop {
    background-color: #e6ffe6;
    border-color: #28a745;
}

/* Group Header for CTE */
.cte-group h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Group Student List (CTE) */
.cte-group-students {

    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
    max-height: 250px;
    overflow-y: auto;
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.cte-group-students .student-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: grab; /* Draggable styling */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 10px;
    flex: 0 1 auto; /* Ensure correct alignment */
    max-width: 200px; /* Limit the width of the student box */
    display: inline-block; /* Each student is a separate block */
}

.cte-group-list {
    flex: 4;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive layout */
    gap: 20px;
    padding: 10px;
    overflow-y: auto;
    max-height: 70vh; /* Keep groups scrollable within viewport */
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.cte-group-students .student-item:hover {
    background-color: #f0f8ff;
    border-color: #007bff;
}

.cte-group-students .student-item:active {
    cursor: grabbing;
}


/* Dragging State for Drop Zones */
.cte-highlight-drop {
    background-color: #eaf4e6;
    border-color: #28a745;
}
.cte-student-item.multi-group {
    background-color: #fff3cd; /* Light yellow */
    border-color: #ffc107; /* Gold border */
}

.cte-student-item.multi-group:hover {
    background-color: #ffecb5;
    border-color: #ffca2c;
}
@media screen and (max-width: 600px) {
    .cte-groups-home {
        flex-direction: column;
    }

    .cte-group {
        grid-template-columns: 1fr;
    }
}


/* Container for the scheduling tool */
.schedule-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

/* Table structure for the schedule */
.schedule-grid {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Header for time slot and teacher columns */
.schedule-grid th {
    font-weight: bold;
    background-color: #e0e0e0;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
}

/* Time slot cell styling */
.schedule-grid td.time-slot {
    font-weight: bold;
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    width: 150px;
}

/* Droppable group cells */
.schedule-grid td.drop-cell {
    padding: 10px;
    border: 1px solid #ccc;
    min-height: 50px;
    background-color: #f9f9f9;
    text-align: center;
    vertical-align: middle;
}

/* Hover effect for group drop areas */
.schedule-grid td.drop-cell:hover {
    background-color: #e9e9e9;
}

/* Styling for the groups being dragged */
.group-item {
    padding: 8px;
    margin: 5px 0;
    background-color: #d1e7dd;
    border: 1px solid #b5d7c3;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    transition: background-color 0.2s ease;
}

/* Hover effect for group items */
.group-item:hover {
    background-color: #c3dfd3;
}

/* Container for available groups */
.available-groups {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f7f7f7;
    width: 100%;
    max-width: 300px;
}

/* Drop cell styles */
.drop-cell {
    padding: 10px;
    border: 1px solid #ccc;
    min-height: 50px;
    text-align: center;
    vertical-align: middle;
}

/* Trash drop zone styling */
.trash-zone {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8d7da;
    border: 1px dashed #d9534f;
    color: #d9534f;
    text-align: center;
    font-weight: bold;
    border-radius: 4px;
}

/* Hover style for trash zone */
.trash-hover {
    background-color: #f1b0b7;
}

/* Container for the teacher selection list */
.teacher-selection-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
}

/* Individual teacher item */
.teacher-item {
    display: flex;
    align-items: center;
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f7f7f7;
    text-align: left;
}
.teacher-header {
    text-align: center;
    font-weight: bold;
    padding: 8px;
    border: 1px solid #ddd;
    background-color: #f0f0f0; /* Optional: background color for visibility */
}

.time-slot {
    text-align: center;
    font-weight: bold;
    padding: 8px;
    border: 1px solid #ddd;
    background-color: #e0e0e0;
}
